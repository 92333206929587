import {
  URL_DASHBOARD,
  URL_SEARCH,
  URL_DATA_SOURCES_STRUCTURED,
  URL_DATASOURCE_FORM,
  URL_ENTITIES,
  URL_SETTINGS,
  URL_DOCUMENTS_CLASSIFIED,
  URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES,
  URL_DOCUMENTS_ALL,
  URL_DOCUMENT,
  URL_ATTRIBUTES,
  URL_CONVERSATIONS,
  URL_EMAILS,
  URL_VIOLATIONS,
  URL_USERS,
  URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES,
  URL_POLICIES,
  URL_DATASOURCE_FORM_UNSTRUCTURED,
  URL_DATABASES,
  URL_TABLES,
  URL_REGISTER_ELASTIC_SEARCH,
  URL_FILTER,
  URL_INSIGHTS,
  URL_PRIVACY_PARTNERS,
  URL_DAYZERO,
  URL_ATTRIBUTES_SET,
  URL_DATA_SOURCES,
  URL_TICKETS,
  URL_RULE_SET,
  URL_DATASOURCE_FORM_STRUCTURED,
  URL_DSR,
  URL_ROPA,
  URL_PRINT_CLOSING,
  URL_PRINT_COVER,
  URL_PRINT_ROPA_CONTENT,
  URL_PRINT_PIA_CONTENT,
  URL_PRINT,
  URL_ENTITIES_DELETED,
  URL_PRINT_DSR_CONTENT,
  URL_MANAGE_ACCOUNT,
  MESSAGES,
  DEFAULT_LANGUAGE,
  URL_COOKIES_CONSENT,
  URL_ONDEMAND,
  URL_DELTA_PRINT,
  URL_PIA,
  URL_FILTERS,
  URL_ACCESS_CONTROL,
  URL_CONSENT_MANAGEMENT,
  ACTION_USER_INFO
} from './constants'
import ThemeContextProvider from './features/dashboards/mainDashboardv2/components/ThemeContext'
import GlobalSearch from './features/globalSearch'
import LoggedInRoute from './routes/loggedInRoute'
import LoggedOutRoute from './routes/loggedOutRoute'
import ElasticSearchForm from './features/dataSources/components/UnstructuredForm/ElasticSeach'
import Notification from './features/notification/notification'
import Modals from './features/modals/modals'
import history from './services/historyService'
import Loading from './features/loading/loading'
import LoaderView from './components/Loader/lb-loader'
import PrintCover from './print/printCover'
import PrintClosingPage from './print/printClosing'
import { RootState } from './rootReducer'
import DeltaPrintPage from './print/delta-report/main'
import PrintPage from './print/print'
import LightBeamDesignSystem from './components/LightbeamDesignSystem'
import SelectFileToCreateFilter from './features/policies/components/SelectFileToCreateFilter'
import { getShowLoader } from './reducers/requestReducer'
import { ACTION_FETCH_FEATURE_FLAGS } from './configs/featureFlagSlice'
import FirstLanding from '../src/features/firstLanding/firstLanding'
import loadable from '@loadable/component'
import React from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

const MainDashBoard = loadable(
  () =>
    import(
      /* webpackChunkName: "main-dashboard" */
      /* webpackMode: "loadable" */ './features/dashboards/mainDashboard/mainDashboard'
    ),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)

const MainDashBoardV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "main-dashboard-v2" */
      /* webpackMode: "loadable" */ './features/dashboards/mainDashboardv2'
    ),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)

const DataSourcesPage = loadable(
  () =>
    import(
      /* webpackChunkName: "dataSource" */
      /* webpackMode: "loadable" */ './features/dataSources/dataSourcesPage'
    ),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const Annotations = loadable(() => import('./features/annotator/annotations'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const EntityDetails = loadable(() => import('./features/entityDetails/entityDetails'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const Insights = loadable(() => import('./features/insights'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const PrivacyPartners = loadable(() => import('./features/privacyPartners'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const EntityListPage = loadable(() => import('./features/entities/entititesListPage'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const SettingsDashboard = loadable(() => import('./features/configurationSettings/settings'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const DataSourceFormUnstructured = loadable(
  () => import('./features/dataSources/components/UnstructuredForm'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const DataSourceTypeForm = loadable(
  () => import('./features/dataSources/components/DataSourceTypeForm/dataSourceTypeForm'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const OnDemandScanning = loadable(
  () => import('./features/onDemandScanningFiles/pageOnDemandScanning'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const ObjectsListPage = loadable(
  () => import('./features/classifications/documentsListByDatasource'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const DocumentsListPage = loadable(() => import('./features/classifications/documentsListPage'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const DashboardMasterContainer = loadable(
  () => import('./features/dashboards/dashboardMasterContainer'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const DataSourceMixedSettings = loadable(
  () => import('./features/dataSourcesMixed/settings/settings'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const DataSourceMixedDashboard = loadable(
  () => import('./features/dataSourcesMixed/dashboard/dashboard'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const PoliciesDashboardContainer = loadable(
  () => import('./features/policies/PoliciesDashboardContainer'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const FilterCreateEdit = loadable(() => import('./features/policies/FilterCreateEdit'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const DsrDashboard = loadable(() => import('./features/dsr/dsr'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const RopaDashboard = loadable(() => import('./features/ropa/ropa'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const PiaDashboard = loadable(() => import('./features/pia/pia'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const CookieConsent = loadable(() => import('./features/cookieConsent/cookieConsentRouter'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const ConsentManagement = loadable(
  () => import('./features/consentManagement/consentManagementRouter'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const PrintRopaReportPage = loadable(() => import('./print/print-ropa'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const PrintPiaReportPage = loadable(() => import('./print/print-pia'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const PrintDsrReportPage = loadable(() => import('./print/print-dsr'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})
const ManageAccountContainer = loadable(
  () => import('./features/manageAccount/manageAccountContainer'),
  {
    fallback: <LoaderView showLoader={true} size={'big'} />
  }
)
const AccessControl = loadable(() => import('./features/accessControl'), {
  fallback: <LoaderView showLoader={true} size={'big'} />
})

const App = (): React.ReactElement | null => {
  const language = useSelector((state: RootState) => state.lang.language)
  const showLoader = useSelector(
    () => getShowLoader(ACTION_USER_INFO) || getShowLoader(ACTION_FETCH_FEATURE_FLAGS)
  )
  const persistedLanguage = language || DEFAULT_LANGUAGE
  return (
    <ThemeContextProvider>
      <IntlProvider locale={persistedLanguage} messages={MESSAGES[persistedLanguage]}>
        <LoaderView showLoader={showLoader} />
        <Router history={history}>
          <Switch>
            <LoggedInRoute path={[`${URL_DASHBOARD}/old`]} exact component={MainDashBoard} />
            <LoggedInRoute
              path={[`${URL_DASHBOARD}/:subPage`, `${URL_DASHBOARD}`]}
              exact
              component={MainDashBoardV2}
            />

            <LoggedInRoute path={URL_SEARCH} component={GlobalSearch} />
            <LoggedInRoute path={URL_DAYZERO} component={FirstLanding} />
            <LoggedInRoute
              path={[
                `${URL_ENTITIES}/:entityId/:subPage`,
                `${URL_ENTITIES}/:entityId`,
                `${URL_VIOLATIONS}/:entityId`,
                `${URL_USERS}/:entityId`
              ]}
              component={EntityDetails}
            />
            <Redirect from={URL_ATTRIBUTES_SET} to={URL_INSIGHTS + URL_ATTRIBUTES_SET} />
            <LoggedInRoute
              path={[
                `${URL_DSR}/:subPage/:itemId/:stepId/:tabId`,
                `${URL_DSR}/:subPage/:itemId/:stepId`,
                `${URL_DSR}/:subPage/:itemId`,
                `${URL_DSR}/:subPage`
              ]}
              component={DsrDashboard}
            />
            <LoggedInRoute
              path={[
                `${URL_COOKIES_CONSENT}/:subPage/:itemId/:stepId/:tabId`,
                `${URL_COOKIES_CONSENT}/:subPage/:itemId/:stepId`,
                `${URL_COOKIES_CONSENT}/:subPage/:itemId`,
                `${URL_COOKIES_CONSENT}/:subPage`
              ]}
              component={CookieConsent}
            />
            <LoggedInRoute
              path={[
                `${URL_CONSENT_MANAGEMENT}/:subPage/:itemId/:stepId/:tabId`,
                `${URL_CONSENT_MANAGEMENT}/:subPage/:itemId/:stepId`,
                `${URL_CONSENT_MANAGEMENT}/:subPage/:itemId`,
                `${URL_CONSENT_MANAGEMENT}/:subPage`,
                `${URL_CONSENT_MANAGEMENT}`
              ]}
              component={ConsentManagement}
            />
            <LoggedInRoute
              path={[
                `${URL_INSIGHTS}/:subPage/:subPageId?/:activeTab?/:activeSubTab?/:activeSubSubTab?`
              ]}
              component={Insights}
            />
            <LoggedInRoute
              path={[`${URL_PRIVACY_PARTNERS}/:subPageId?/:activeTab?`]}
              component={PrivacyPartners}
            />
            <LoggedInRoute
              path={[URL_ENTITIES, URL_ENTITIES_DELETED, URL_VIOLATIONS, URL_USERS]}
              component={EntityListPage}
            />
            <LoggedInRoute
              path={[URL_DATA_SOURCES, URL_DATA_SOURCES_STRUCTURED]}
              exact
              component={DataSourcesPage}
            />
            <LoggedInRoute
              path={[
                `${URL_DATA_SOURCES}/:dataSourceId/:subPage/:subPageId?/:activeTab?`,
                `${URL_DATA_SOURCES}/:dataSourceId/:subPage/:subPageId?/:activeTab?/:tabId?`,
                `${URL_DATA_SOURCES}/:dataSourceId`
              ]}
              exact
              component={DashboardMasterContainer}
            />
            <LoggedInRoute
              path={[
                URL_DATA_SOURCES_STRUCTURED + '/:dataSourceId/:subPage',
                URL_DATA_SOURCES_STRUCTURED + '/:dataSourceId',
                URL_DATABASES + '/:databaseId/:subPage/:clusterId',
                URL_DATABASES + '/:databaseId/:subPage',
                URL_DATABASES + '/:databaseId',
                URL_TABLES + '/:tableId/:subPage',
                URL_TABLES + '/:tableId'
              ]}
              component={DataSourceMixedDashboard}
            />
            <LoggedInRoute
              path={URL_DATASOURCE_FORM_STRUCTURED}
              component={DataSourceMixedSettings}
            />
            <LoggedInRoute
              path={URL_DATASOURCE_FORM_UNSTRUCTURED}
              component={DataSourceFormUnstructured}
            />
            <LoggedInRoute path={URL_ONDEMAND} component={OnDemandScanning} />
            <LoggedInRoute path={URL_DATASOURCE_FORM} component={DataSourceTypeForm} />
            <LoggedInRoute path={URL_REGISTER_ELASTIC_SEARCH} component={ElasticSearchForm} />
            <LoggedInRoute
              path={[
                URL_POLICIES,
                `${URL_RULE_SET}/:subPage`,
                `${URL_POLICIES}/:subPage`,
                `${URL_POLICIES}/:subPage/:id`,
                `${URL_POLICIES}/:subPage/:id/:alertSubPage`,
                `${URL_POLICIES}/:subPage/:id/:alertSubPage/:alertSubPageId`
              ]}
              exact
              component={PoliciesDashboardContainer}
            />
            <LoggedInRoute
              path={[
                URL_MANAGE_ACCOUNT
                // `${URL_LANGUAGE}/:subPage`,
              ]}
              exact
              component={ManageAccountContainer}
            />
            <LoggedInRoute
              path={`${URL_SETTINGS}/:subPage/:subPageId?`}
              exact
              component={SettingsDashboard}
            />
            <LoggedInRoute
              path={[
                URL_INSIGHTS + URL_DOCUMENTS_CLASSIFIED,
                URL_INSIGHTS + URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES
              ]}
              exact
              component={DocumentsListPage}
            />
            <LoggedInRoute path={URL_DOCUMENTS_ALL} exact component={ObjectsListPage} />
            <LoggedInRoute
              path={[
                `${URL_DOCUMENT}`,
                `${URL_DOCUMENTS_ALL}/:documentId`,
                `${URL_DOCUMENTS_CLASSIFIED}/:documentId`,
                `${URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES}/:documentId`,
                `${URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES}/:documentId`,
                `${URL_CONVERSATIONS}/:documentId`,
                `${URL_ATTRIBUTES}/:documentId`,
                `${URL_EMAILS}/:documentId`,
                `${URL_TICKETS}/:documentId`
              ]}
              exact
              hideNavBar
              component={Annotations}
            />
            <LoggedInRoute
              path="/lightbeam-design-system"
              exact
              component={LightBeamDesignSystem}
            />
            <LoggedInRoute path={URL_FILTERS} exact component={SelectFileToCreateFilter} />
            <LoggedInRoute
              path={[URL_FILTER, `${URL_FILTER}/:filterId`]}
              exact
              component={FilterCreateEdit}
            />
            <LoggedInRoute
              path={[
                `${URL_ROPA}/:subPage/:stepId/:subSubPage/:tabId`,
                `${URL_ROPA}/:subPage/:stepId/:subSubPage`,
                `${URL_ROPA}/:subPage/:stepId`,
                `${URL_ROPA}/:subPage`,
                URL_ROPA
              ]}
              component={RopaDashboard}
            />
            <LoggedInRoute
              path={[
                `${URL_PIA}/:subPage/:stepId/:subSubPage/:tabId`,
                `${URL_PIA}/:subPage/:stepId/:subSubPage`,
                `${URL_PIA}/:subPage/:stepId`,
                `${URL_PIA}/:subPage`,
                URL_PIA
              ]}
              component={PiaDashboard}
            />
            <LoggedInRoute
              path={[
                `${URL_ACCESS_CONTROL}/:subPage/:subSubPage/:tabId`,
                `${URL_ACCESS_CONTROL}/:subPage/:subSubPage`,
                `${URL_ACCESS_CONTROL}/:subPage`,
                URL_ACCESS_CONTROL
              ]}
              component={AccessControl}
            />
            <LoggedOutRoute path={URL_PRINT_ROPA_CONTENT} exact component={PrintRopaReportPage} />
            <LoggedOutRoute path={URL_PRINT_PIA_CONTENT} exact component={PrintPiaReportPage} />
            <LoggedOutRoute path={URL_PRINT_DSR_CONTENT} exact component={PrintDsrReportPage} />
            <LoggedOutRoute path={URL_DELTA_PRINT} exact component={DeltaPrintPage} />
            <LoggedOutRoute path={URL_PRINT} exact component={PrintPage} />
            <LoggedOutRoute
              path={URL_PRINT_COVER}
              exact
              component={PrintCover}
              date={new Date().toLocaleString('en-US')}
            />
            <LoggedOutRoute path={URL_PRINT_CLOSING} exact component={PrintClosingPage} />
            <LoggedOutRoute path={'/'} exact component={Loading} />
            <Redirect from={'*'} to={URL_DASHBOARD} />
          </Switch>

          <Notification />
          <Modals />
        </Router>
      </IntlProvider>
    </ThemeContextProvider>
  )
}

export default App
