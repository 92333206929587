import service from '../../services/api/apiService'
import { ACTION_USER_INFO, UserRoles, accessTokenKey } from '../../constants'
import { API_ERROR_STATUS } from '../../services/api/apiStatuses'
import { STATES, UserDetails } from '../../interfaces'
import { RootState } from '../../rootReducer'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
export interface UserState {
  isAuthenticated: boolean
  fetchingAuthStatusState: STATES
  isPasswordReset: boolean
  isReadOnly: boolean
  userInfo?: UserDetails
  serverError?: boolean
}
export interface Token_Params {
  session_state: string
  code: string
  redirectURI: string
}
export const initialState: UserState = {
  isAuthenticated: !!window.localStorage.getItem(accessTokenKey),
  fetchingAuthStatusState: STATES.success,
  isPasswordReset: false,
  userInfo: {} as UserDetails,
  isReadOnly: true,
  serverError: false
}

export const fetchUserInfo = createAsyncThunk(
  ACTION_USER_INFO,
  async (_, { rejectWithValue, dispatch }): Promise<UserDetails> => {
    const userInfo = await service.getUserInfo().catch((e) => {
      dispatch(logout())
      return rejectWithValue({ loggedInViaSaaS: e.loggedInViaSaaS })
    })
    return userInfo
  }
)
export const ACTION_TOKEN = 'fetch/token'
export const fetchToken = createAsyncThunk(
  ACTION_TOKEN,
  async (params: Token_Params, { rejectWithValue }) => {
    const tokenRes = await service.getToken(params).catch((e) => {
      const statusMessage =
        e?.message === API_ERROR_STATUS.UNAUTHORIZED ? null : 'login.error.invalidCredentials'

      return rejectWithValue({ statusMessage })
    })
    window.localStorage.setItem(accessTokenKey, tokenRes[accessTokenKey])
    return tokenRes
  }
)

export const ACTION_LOGOUT = 'auth/logout'
export const logout = createAsyncThunk(ACTION_LOGOUT, async (_, { getState }) => {
  const res = await service.logout().finally(() => {
    const state = getState() as RootState
    if (!state.user.serverError) {
      localStorage.clear()
    }
  })
  return res
})

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setServerError: (state, action: PayloadAction<boolean>) => {
      state.serverError = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false
    })
    builder.addCase(fetchToken.fulfilled, (state) => {
      state.isAuthenticated = true
      state.fetchingAuthStatusState = STATES.success
    })
    builder.addCase(fetchToken.rejected, (state) => {
      state.isAuthenticated = false
      state.fetchingAuthStatusState = STATES.failed
    })
    builder.addCase(fetchToken.pending, (state) => {
      state.fetchingAuthStatusState = STATES.pending
    })
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.fetchingAuthStatusState = STATES.pending
    })
    builder.addCase(fetchUserInfo.rejected, (state) => {
      state.isAuthenticated = false
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload
      if (payload && payload.roles && payload.roles.length) {
        state.isReadOnly = payload.roles[0].name !== UserRoles.admin
      }
    })
  }
})
export const { setAuth, setServerError } = authenticationSlice.actions
export default authenticationSlice.reducer
